import React from "react";
import "./Starter.scss";
const Starter = () => {
  return (
    <div className="container">
      <h1>
        Console
        <span style={{ color: "white" }}>.</span>
        <span style={{ color: "#61afef" }}>log</span>("
      </h1>
      <div className="string">
        <h1 className="greeting welcome">Welcome!</h1>
        <h1 className="greeting loading1">Loading.!</h1>
        <h1 className="greeting loading2">Loading..!</h1>
        <h1 className="greeting loading3">Loading...!</h1>
      </div>
      <h1 className="closure">");</h1>
    </div>
  );
};

export default Starter;
