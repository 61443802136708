import React, { useEffect, useState } from "react";
import { client, urlFor } from "../../client";
import { AppWrap, MotionWrap } from "../../wrapper";
import { motion } from "framer-motion";
import "./Footer.scss";

const Footer = () => {
  const [contact, setContact] = useState({});
  const [myImage, setMyImage] = useState({});
  const [loading, setLoading] = useState(true);
  const [formLoading, setFormLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const { name, email, message } = formData;

  useEffect(() => {
    const query = '*[_type == "contact"]';
    const imageQuery = '*[_type == "about"]{imageURL1}';
    Promise.all([
      client.fetch(imageQuery).then((data) => {
        setMyImage(data[0]?.imageURL1);
      }),
      client.fetch(query).then((data) => {
        setContact(data.find((item) => item.index === "main"));
      }),
    ]).then(() => {
      setLoading(false);
    });
  }, []);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormLoading(true);
    const contact = {
      _type: "contact",
      index: "",
      name: name,
      email: email,
      message: message,
    };

    client.create(contact).then(() => {
      setFormLoading(false);
      setIsSubmitted(true);
    });
  };

  return loading ? (
    <div />
  ) : (
    <>
      <h2 className="head-text">{contact?.name}</h2>
      <p className="p-text">{contact?.message}</p>
      <div className="footer__container app__flex" id="experience">
        <motion.div className="footer__wrap ">
          <motion.div
            whileInView={{ opacity: [0, 1] }}
            transition={{ duration: 0.5 }}
            className="footer__img"
          >
            <img src={urlFor(myImage)} alt="Mohammed Saif" />
          </motion.div>
          {!isSubmitted ? (
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 0.5 }}
              className="footer__contact-form app__flex"
            >
              <div className="app__flex">
                <input
                  className="p-text"
                  type="text"
                  placeholder="Your Name"
                  name="name"
                  value={name}
                  onChange={handleChangeInput}
                />
              </div>
              <div className="app__flex">
                <input
                  className="p-text"
                  type="text"
                  placeholder="Your Email"
                  name="email"
                  value={email}
                  onChange={handleChangeInput}
                />
              </div>
              <div className="app__flex">
                <textarea
                  className="p-text"
                  placeholder="Message"
                  name="message"
                  value={message}
                  onChange={handleChangeInput}
                />
              </div>
              <button type="button" className="p-text" onClick={handleSubmit}>
                {formLoading ? "Sending now..." : "Send >"}
              </button>
            </motion.div>
          ) : (
            <div>
              <h3 className="head-text">Message sent, Thank you very much.</h3>
            </div>
          )}
        </motion.div>
      </div>
    </>
  );
};

export default AppWrap(MotionWrap(Footer, "footer"), "contact", "app__whitebg");
