import React, { useEffect, useState } from "react";

import { BsMessenger, BsLinkedin, BsGithub, BsYoutube } from "react-icons/bs";
import { client } from "../../client";

const SocialMedia = () => {
  const [socialMedia, setSocialMedia] = useState([]);
  useEffect(() => {
    const query = '*[_type == "socialMedia"]';
    client.fetch(query).then((data) => {
      setSocialMedia(data);
    });
  }, []);

  const socialList = [
    { title: "github", component: <BsGithub /> },
    { title: "linkedin", component: <BsLinkedin /> },
    { title: "messenger", component: <BsMessenger /> },
    { title: "youtube", component: <BsYoutube /> },
  ];

  return (
    <div className="app__social">
      {socialList.map((item) => (
        <a
          href={socialMedia.find((media) => media.title === item.title)?.url}
          target="_blank"
          rel="noreferrer"
          key={item.title}
        >
          <div key={item.title}>{item.component}</div>
        </a>
      ))}
    </div>
  );
};

export default SocialMedia;
