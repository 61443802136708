import React, { useState, useEffect, useRef } from "react";
import { Navbar, Starter } from './components';
import { Footer, Header, Projects, Qualifications } from './container';
import { client } from "./client";
import { audio, images } from "./constants";
import { AiOutlineDownload } from "react-icons/ai";
import './App.scss';

const App = () => {
  const audioEl = useRef(null);
  const [resume, setResume] = useState("");
  const [loading, setLoading] = useState(true);

  const audioFiles = [audio.deepFocus, audio.jazzyHipHop, audio.saxLofi, audio.softLofi, audio.studyLofi];
  const preShuffledAudio = audioFiles.sort(() => Math.random() - 0.5);

  const shuffle = () => {
    audioFiles.sort(() => Math.random() - 0.5);
    audioEl.current.src = audioFiles[0];
    audioEl.current.play();
  };

  const play = () => {
    audioEl.current.play();
  };

  const pause = () => {
    audioEl.current.pause();
  };

  useEffect(() => {
    const query = '*[_type == "about"]{imageURL1,"fileURL":resume.asset->url}';
    client.fetch(query).then((data) => {
      setResume(data[0].fileURL);
    });
  }, []);

  setTimeout(() => {
    setLoading(false);
  }, 3000);

  if (loading)
  {
    return <Starter />;
  }

  return (
    <div className='app'>
      <div className="app__media-player">
        <img onClick={play} src={images.playAudio} alt="play button" />
        <img onClick={pause} src={images.pauseAudio} alt="pause button" />
        <img onClick={shuffle} src={images.shuffleAudio} alt="shuffle button" />
      </div>
      <div for="resume" className="about__resume">
        <a download href={resume} className="about__download-resume">
          <span>Download</span>CV
          <AiOutlineDownload />
        </a>
      </div>
      <Navbar resume={resume} />
      <Header />
      <Qualifications />
      <Projects />
      <Footer />
      <audio ref={audioEl} src={preShuffledAudio[0]} />
    </div>
  );
};

export default App;