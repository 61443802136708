import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { client, urlFor } from "../../client";
import Typewriter from "typewriter-effect";
import "./Header.scss";

import { AppWrap } from "../../wrapper";
const scaleVariant = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  },
};
const Header = () => {
  const [headerData, setHeaderData] = useState([]);
  const [skillsData, setSkillsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const titles = [
    `${headerData.title1} 👨‍💻`,
    `${headerData.title2} 🖥️`,
    `${headerData.title3} 😎`,
  ];

  useEffect(() => {
    const query = '*[_type in ["about","skill"]]';
    client.fetch(query).then((data) => {
      setHeaderData(data.find((item) => item._type === "about"));
      setSkillsData(
        data.filter(
          (item) =>
            item.name === "MySQL" ||
            item.name === "NodeJS" ||
            item.name === "React" ||
            item.name === "JavaScript"
        )
      );
      setLoading(false);
    });
  }, []);

  if (loading) {
    return null;
  }

  return (
    <div className="header app__flex">
      <motion.div
        whileInView={{ x: [-150, 0], opacity: [0, 1] }}
        transition={{ duration: 0.6 }}
        className="header-info"
      >
        <div className="header__badge">
          <div className="header__badge-wrap app__flex">
            <span className="header__badge-wave">👋🏾</span>
            <div className="header__badge-name">
              <p className="p-text">Hello!, My name is</p>
              <h1 className="head-text">{headerData.name}</h1>
            </div>
          </div>

          <div className="header__tag-wrap app__flex">
            <p className="p-text">I am</p>
            <div className="header__tag-typewriter p-text">
              <Typewriter
                options={{
                  strings: titles,
                  autoStart: true,
                  delay: 100,
                  loop: true,
                  cursor: "|",
                }}
              />
            </div>
          </div>
        </div>
      </motion.div>

      <motion.div
        whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="header__image"
      >
        <img src={urlFor(headerData.logo)} alt={headerData.name} />
      </motion.div>
      <motion.div
        variants={scaleVariant}
        whileInView={scaleVariant.whileInView}
        className="header__circles"
      >
        {skillsData.map((skill, index) => (
          <div
            className="header__circle-wrapper app__flex"
            key={`circle-${index}`}
          >
            <img src={urlFor(skill.icon)} alt="skill-circle" />
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default AppWrap(Header, "home");
