import React, { useState, useEffect } from "react";
import { AppWrap, MotionWrap } from "../../wrapper";
import { motion } from "framer-motion";
import { urlFor, client } from "../../client";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "./Qualifications.scss";

const Qualifications = () => {
  const [selectedTab, setSelectedTab] = useState("skills");
  const [skills, setSkills] = useState([]);
  const [experience, setExperience] = useState([]);
  const [education, setEducation] = useState([]);

  useEffect(() => {
    const skillQuery = '*[_type == "skill"]';

    const experienceQuery =
      '*[_type == "experience"]{from,to,jobTitle,description,"company":company->{title, url},"techStack":companyStack[]-> { name, icon }} | order(to desc)';

    const educationQuery =
      '*[_type == "education"]{from,to,courseTitle,description,"institute":institute->{title, url}} | order(from desc)';

    Promise.all([
      client.fetch(skillQuery).then((data) => {
        setSkills(data);
      }),
      client.fetch(experienceQuery).then((data) => {
        setExperience(data);
      }),
      client.fetch(educationQuery).then((data) => {
        setEducation(data);
      }),
    ]);
  }, []);

  const monthAbbreviations = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const getDate = (fromDate, toDate) => {
    const fromYear = new Date(fromDate).getFullYear();
    const fromMonth = new Date(fromDate).getMonth();
    const fromMonthAbbreviation = monthAbbreviations[fromMonth];
    const toYear = new Date(toDate).getFullYear();
    const toMonth = new Date(toDate).getMonth();
    const toMonthAbbreviation = monthAbbreviations[toMonth];

    const fromFinalDate = `${fromYear}/${fromMonthAbbreviation}`;
    const toFinalDate =
      fromDate === toDate ? "Present" : `${toYear}/${toMonthAbbreviation}`;

    const finalDate = `${fromFinalDate} - ${toFinalDate}`;
    return finalDate;
  };

  return (
    <>
      <h2 className="head-text">Qualifications</h2>
      <motion.div
        whileInView={{ x: [-300, 0] }}
        transition={{ duration: 0.5, ease: "easeOut" }}
        className="qualification__filter app__flex"
      >
        <ul className="qualification__filter-menu">
          {["experience", "skills", "education"].map((tab) => (
            <button
              className={`p-text qualification__filter-button ${
                selectedTab === tab ? "qualification__filter-active" : ""
              }`}
              data-target={`#${tab}`}
              onClick={() => setSelectedTab(tab)}
              key={tab}
            >
              {tab}
            </button>
          ))}
        </ul>
      </motion.div>
      {selectedTab === "skills" && (
        <div className="qualification__container" id="skills">
          <motion.div className="skills__list">
            {skills.map((skill, index) => (
              <Tippy
                arrow={false}
                className="skills__tooltip"
                content={<span className="bold-text">{skill.description}</span>}
                key={`${skill.name}_${index}`}
              >
                <motion.div
                  whileInView={{ opacity: [0, 1] }}
                  transition={{ duration: 0.5 }}
                  className="skills__item app__flex"
                  key={`${skill.name}_${index}`}
                >
                  <div className="skills__item-bg app__flex">
                    <img src={urlFor(skill.icon)} alt={skill.name} />
                    <motion.div
                      whileHover={{ opacity: [0, 1] }}
                      transition={{
                        duration: 0.25,
                        ease: "easeInOut",
                        staggerChildren: 0.5,
                      }}
                      className="skills__item-hover app__flex"
                    >
                      <p className="p-text">{skill.name}</p>
                      <p className="p-text">{skill.percentage}%</p>
                    </motion.div>
                  </div>
                </motion.div>
              </Tippy>
            ))}
          </motion.div>
        </div>
      )}
      {selectedTab === "experience" && (
        <div className="qualification__container" id="experience">
          <motion.div className="qualification__list">
            {experience.map((work) => (
              <Tippy
                arrow={false}
                className="qualification__tooltip"
                content={work.description.map((content) => (
                  <ul>
                    <li>{content.children[0].text}</li>
                  </ul>
                ))}
                key={work.to}
              >
                <motion.div
                  className="qualification__item app__flex"
                  key={work.from}
                >
                  <div className="qualification__date">
                    <p className="bold-text">{getDate(work.from, work.to)}</p>
                  </div>
                  <motion.div
                    whileInView={{ opacity: [0, 1] }}
                    transition={{ duration: 0.5 }}
                    className="qualification__info-wrap"
                    key={work.jobTitle.replace(/\s+/g, "_").toLowerCase()}
                  >
                    <h4 className="bold-text">{work.jobTitle}</h4>
                    <a href={work.company.url} target="_blank" rel="noreferrer">
                      <p className="p-text">
                        <span> @ </span>
                        {work.company.title}
                      </p>
                    </a>
                    <div className="experience__stack app__flex">
                      {work.techStack.map((stack) => (
                        <img
                          className="experience__stack-img"
                          src={urlFor(stack.icon)}
                          alt={stack.name}
                        />
                      ))}
                    </div>
                  </motion.div>
                </motion.div>
              </Tippy>
            ))}
          </motion.div>
        </div>
      )}
      {selectedTab === "education" && (
        <div className="qualification__container" id="education">
          <motion.div className="qualification__list">
            {education.map((course) => (
              <Tippy
                arrow={false}
                className="qualification__tooltip"
                content={course.description.map((content) => (
                  <ul>
                    <li>{content.children[0].text}</li>
                  </ul>
                ))}
                key={course.to}
              >
                <motion.div
                  className="qualification__item app__flex"
                  key={course.from}
                >
                  <div className="qualification__date">
                    <p className="bold-text">
                      {getDate(course.from, course.to)}
                    </p>
                  </div>
                  <motion.div
                    whileInView={{ opacity: [0, 1] }}
                    transition={{ duration: 0.5 }}
                    className="qualification__info-wrap"
                    key={course.courseTitle.replace(/\s+/g, "_").toLowerCase()}
                  >
                    <h4 className="bold-text">{course.courseTitle}</h4>
                    <a
                      href={course.institute.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p className="p-text">
                        <span> @ </span>
                        {course.institute.title}
                      </p>
                    </a>
                  </motion.div>
                </motion.div>
              </Tippy>
            ))}
          </motion.div>
        </div>
      )}
    </>
  );
};

export default AppWrap(
  MotionWrap(Qualifications, "qualification"),
  "qualification",
  "app__whitebg"
);
