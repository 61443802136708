import React, { useEffect, useState } from "react";
import { client } from "../../client";
import "./Navbar.scss";

const Navbar = () => {
  const [mobileNav, setMobileNav] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 800;

  const navItems = ["home", "qualification", "projects", "contact"];

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  useEffect(() => {
    const query = '*[_type == "navbar"]';
    client.fetch(query).then((data) => {
      setMobileNav(data);
    });
  }, []);

  if (width > breakpoint) {
    return (
      <nav className="navbar">
        <ul className="navbar-links">
          {navItems.map((item) => (
            <li className="app__flex p-text" key={`link-${item}`}>
              <div />
              <a href={`#${item}`}>{item}</a>
            </li>
          ))}
        </ul>
      </nav>
    );
  }

  return (
    <div className="mobile__nav-header">
      <nav className="mobile__navbar">
        <ul>
          {mobileNav.map((item) => (
            <li key={item.name}>
              <a className="mobile__nav-link" href={`#${item.name}`}>
                <i className={item.link}></i> {item.name}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
