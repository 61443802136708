import React, { useState, useEffect } from "react";
import { AppWrap, MotionWrap } from "../../wrapper";
import { AiFillEye, AiFillGithub } from "react-icons/ai";
import { motion } from "framer-motion";
import { urlFor, client } from "../../client";
import "./Projects.scss";

const Projects = () => {
  const [projects, setProjects] = useState([]);
  useEffect(() => {
    const query =
      '*[_type == "project"]{codeLink,description,preview,title,viewLink,tags,"techStack":stacks[]-> { name, icon }}';
    client.fetch(query).then((data) => {
      setProjects(data);
    });
  }, []);

  return (
    <>
      <h2 className="head-text">Projects</h2>
      <motion.div
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="project__wrap"
      >
        {projects.map((project, index) => (
          <div className="project__item app__flex" key={index}>
            <div className="project__item-img app__flex">
              <img src={urlFor(project.preview)} alt={project.title} />

              <motion.div
                whileHover={{ opacity: [0, 1] }}
                transition={{
                  duration: 0.25,
                  ease: "easeInOut",
                  staggerChildren: 0.5,
                }}
                className="project__item-hover app__flex"
              >
                <a href={project.viewLink} target="_blank" rel="noreferrer">
                  <motion.div
                    whileInView={{ scale: [0, 1] }}
                    whileHover={{ scale: [1, 0.9] }}
                    transition={{ duration: 0.25 }}
                    className="app__flex"
                  >
                    <AiFillEye />
                  </motion.div>
                </a>
                <a href={project.codeLink} target="_blank" rel="noreferrer">
                  <motion.div
                    whileInView={{ scale: [0, 1] }}
                    whileHover={{ scale: [1, 0.9] }}
                    transition={{ duration: 0.25 }}
                    className="app__flex"
                  >
                    <AiFillGithub />
                  </motion.div>
                </a>
              </motion.div>
            </div>

            <div className="project__item-content app__flex">
              <h4 className="bold-text">{project.title}</h4>
              <p className="p-text" style={{ marginTop: 10 }}>
                {project.description[0].children[0].text}
              </p>

              <div className="project__item-tag app__flex">
                {project.techStack.map((stack) => (
                  <img
                    className="project__item-stack"
                    src={urlFor(stack.icon)}
                    alt={stack.name}
                    key={stack.name}
                  />
                ))}
              </div>
            </div>
          </div>
        ))}
      </motion.div>
    </>
  );
};

export default AppWrap(MotionWrap(Projects, "project"), "projects");
