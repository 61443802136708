import deepFocus from '../assets/audios/deepFocus.mp3';
import jazzyHipHop from '../assets/audios/jazzyHipHop.mp3';
import saxLofi from '../assets/audios/saxLofi.mp3';
import softLofi from '../assets/audios/softLofi.mp3';
import studyLofi from '../assets/audios/studyLofi.mp3';



export default {
    deepFocus,
    jazzyHipHop,
    saxLofi,
    softLofi,
    studyLofi,
};